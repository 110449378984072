import React from 'react';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { Footer, Header } from '@app/components/template';
import blogBack from '@app/images/blog-back.jpg';
import { Seo } from '@app/components';

export default () => {
  const intl = useIntl();
  return (
    <>
      <Seo title={ intl.formatMessage({ id: 'seo_title_privacy' }) }
           description={ intl.formatMessage({ id: 'seo_description_second' }) } />
      <div className="main-content-wraper privacy-page">
        <div className="main-content-inner Post">
          <Header />

          <section className="breadcrumb-section"
                   style={ {
                     backgroundImage: `url(${ blogBack })`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center top',
                     backgroundRepeat: 'no-repeat'
                   } }>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center breadcrumb-content">
                    <h2><FormattedMessage id="footer_privacy" /></h2>
                    <ul className="breadcrumb">
                      <li>
                        <Link to='/'>
                          <FormattedMessage id="footer_home" />
                        </Link>
                      </li>
                      <li>
                        <FormattedMessage id="footer_privacy" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-details-area bg-secondary ptb-60">
            <div className="blog-details-area-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2">
                    <div className="blog-single-content-wrap">
                      <h6 className="privacy-page__list-header">§1 Postanowienia Ogólne</h6>
                      <ol className="privacy-page__main-list">
                        <li>Administratorem Państwa danych osobowych jest Paweł Radomski prowadzący działalność
                          gospodarczą pod firmą „Setenta Paweł Radomski”, ze stałym miejscem wykonywania działalności
                          gospodarczej w Domaszczynie (55-095) pod adresem: ul. Przylesie 76, zarejestrowaną w
                          Centralnej Ewidencji i Informacji o Działalności Gospodarczej, NIP 6472489879, REGON 362711060
                          („<strong>Administrator</strong>”). Z Administratorem możesz skontaktować się pod numerem
                          telefonu (+48) 793 432 042 lub za pomocą adresu e-mail: <a
                            href="mailto:kontakt@kartazgloszen.pl">kontakt@kartazgloszen.pl</a>.
                        </li>
                        <li>
                          Terminy użyte w niniejszej Polityce Prywatności oznaczają:
                          <ol type="a">
                            <li><strong>Aplikacja</strong> – aplikację webową „Karta Zgłoszeń” wspierającą organizację
                              turniejów tanecznych;
                            </li>
                            <li><strong>RODO</strong> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z
                              dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                              danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
                              95/46/WE;
                            </li>
                            <li><strong>Serwis</strong> – stronę internetową <Link
                              to="/">www.kartazgloszen.pl</Link> wraz z podstronami;
                            </li>
                            <li><strong>Użytkownik</strong> – osobę korzystająca z Aplikacji lub Serwisu lub osobę,
                              której dane osobowe zostały dodane w Aplikacji.
                            </li>
                          </ol>
                        </li>
                        <li>Administrator przestrzega zasad stosowania odpowiednich środków techniczno-organizacyjnych
                          określonych w RODO, w tym w szczególności dotyczących odpowiedniego zabezpieczenia danych
                          osobowych przed niezgodnym z prawem dostępem, modyfikacją zniszczeniem danych przez osoby
                          nieuprawnione.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 2 Kategorie danych, cele i podstawy przetwarzania</h6>
                      <ol className="privacy-page__main-list">
                        <li>Administrator może zbierać następujące kategorie danych: dane logowania do Aplikacji, imię,
                          nazwisko, adres e-mail, nazwa firmy, adres, rok urodzenia, klasa taneczna, wizerunek, dane
                          eksploatacyjne związane z korzystaniem z Serwisu i Aplikacji (m.in. IP, domena, urządzenie
                          końcowe).
                        </li>
                        <li>
                          Podane przez Użytkowników dane osobowe będą przetwarzane w następujących celach i na
                          następujących podstawach:
                          <ol type="a">
                            <li>w celu komunikacji z Użytkownikami i udzielenia odpowiedzi na otrzymywane od
                              Użytkowników wiadomości, np. z formularzy kontaktowych dostępnych w Serwisie lub
                              Aplikacji, co stanowi prawnie uzasadniony interes Administratora (art. 6 lit. f RODO),
                            </li>
                            <li>w celach związanych z korzystaniem przez Użytkownika z Aplikacji, obsługi konta w
                              Aplikacji, udzielenia wsparcia technicznego, tj. dla celów wykonania umowy o korzystanie z
                              Aplikacji lub do podjęcia działań na żądanie Użytkownika przed zawarciem umowy (art. 6
                              ust. 1 lit. b RODO),
                            </li>
                            <li>w celu organizacji turniejów tanecznych przez organizatorów tanecznych, w tym
                              archiwizacji danych z tym związanych, co stanowi prawnie uzasadniony interes osoby
                              trzeciej – organizatorów turniejów tanecznych (art. 6 ust. 1 lit. f RODO),
                            </li>
                            <li>w przypadku otrzymania danych od innego Użytkownika, co może mieć miejsce jeśli szkoła
                              tańca dodaje w Aplikacji dane swoich tancerzy lub zgłasza ich do udziału w turniejach
                              tanecznych, dane te przetwarzane są w celu realizacji umowy o korzystanie z Aplikacji
                              zawartej z Użytkownikiem, co stanowi prawnie uzasadniony interes Administratora (art. 6
                              ust. 1 lit. f RODO),
                            </li>
                            <li>w celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami, co stanowi
                              prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO),
                            </li>
                            <li>w celu oferowania produktów i usług Administratora, co stanowi prawnie uzasadniony
                              interes Administratora w postaci możliwości prowadzenia marketingu bezpośredniego (art. 6
                              ust. 1 lit. f RODO),
                            </li>
                            <li>w celach archiwalnych i dowodowych dla zabezpieczenia informacji na wypadek prawnej
                              potrzeby wykazania faktów, w tym dopełnienia obowiązku rozliczalności wynikającego z RODO,
                              co jest prawnie uzasadnionym interesem Administratora (art. 6 ust. 1 lit. f RODO),
                            </li>
                            <li>w pozostałych przypadkach, tam gdzie przewidziano możliwość wyrażenia zgody na
                              przetwarzanie danych osobowych, dane będą przetwarzane w zakresie i w celu wyrażonym w
                              treści zgody (art. 6 ust. 1 lit. a RODO). Jeżeli Użytkownik wyraził zgodę na otrzymywanie
                              komunikatów marketingowych na adres e-mail oraz numer telefonu, to podstawą prawną będzie
                              także art. 10 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną oraz
                              art. 172 ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne.
                            </li>

                          </ol>
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 3 Prawa Użytkowników</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          <li>Każdemu Użytkownikowi przysługuje prawo do:</li>
                          <ol type="a">
                            <li>dostępu do swoich danych oraz otrzymania ich kopii,</li>
                            <li>sprostowania (poprawiania) swoich danych,</li>
                            <li>usunięcia danych, jeśli jest to dopuszczalne na gruncie RODO,</li>
                            <li>ograniczenia przetwarzania danych, w takim zakresie jakim zezwala na to RODO,</li>
                            <li>wniesienia sprzeciwu wobec przetwarzania danych przetwarzanych w celu realizacji prawnie
                              uzasadnionego interesu Administratora lub osoby trzeciej, w tym prowadzenia marketingu
                              bezpośredniego, a także, gdy przetwarzanie jest niezbędne Administratorowi do wykonania
                              zadania realizowanego w interesie publicznym lub do sprawowania powierzonej
                              Administratorowi władzy publicznej,
                            </li>
                            <li>przenoszenia danych na warunkach określonych w RODO,</li>
                            <li>wniesienia skargi do organu nadzorczego – jeżeli Użytkownik uważa, że Administrator
                              przetwarza jego dane niezgodnie z prawem, może złożyć w tej sprawie skargę do Prezesa
                              Urzędu Ochrony Danych Osobowych,
                            </li>
                            <li>cofnięcia zgody na przetwarzanie danych osobowych – w każdej chwili Użytkownik ma prawo
                              cofnąć zgodę na przetwarzanie tych danych osobowych, które Administrator przetwarza na
                              podstawie jego zgody; cofnięcie zgody nie będzie wpływać na zgodność z prawem
                              przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
                            </li>
                          </ol>
                        </li>
                        <li>Podanie danych osobowych jest dobrowolne, lecz odmowa ich podania uniemożliwi stworzenie
                          konta w Aplikacji, korzystanie z funkcjonalności Aplikacji, w tym zgłoszenie się na turnieje
                          taneczne, czy też otrzymanie odpowiedzi na wysłane zapytanie.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 4 Odbiorcy danych</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          Dostęp do danych osobowych Użytkowników mogą mieć:
                          <ol type="a">
                            <li>organizatorzy turniejów tanecznych, którzy mogą być w tym zakresie odrębnymi
                              administratorami danych,
                            </li>
                            <li>osoby zgłaszające się do turniejów tanecznych,</li>
                            <li>pozostali uczestnicy turniejów tanecznych oraz szkoły tańca zgłaszające tancerzy do
                              turniejów,
                            </li>
                            <li>podmioty w zakresie realizacji procesów płatności dostępnych w Aplikacji lub Serwisie –
                              operatorzy płatności, mogący być w tym zakresie odrębnymi administratorami danych,
                            </li>
                            <li>dostawcy usług zaopatrujący Administratora w rozwiązania techniczne i organizacyjne, w
                              szczególności podmioty świadczące usługi w obszarze IT, hostingu, marketingu, analityki,
                            </li>
                            <li>biura księgowe, doradcy podatkowi, podmioty świadczące pomoc prawną,</li>
                            <li>właściwe organy państwa, w oparciu o obowiązujące przepisy prawa.</li>
                          </ol>
                        </li>
                        <li>Administrator nie będzie przekazywać ani zezwalać na przekazywanie danych osobowych poza
                          terytorium Europejskiego Obszaru Gospodarczego z wyjątkiem podmiotów wobec których podjęte
                          zostaną środki niezbędne do zapewnienia, że przekazanie jest zgodne z RODO. Przedmiotowe
                          środki mogą obejmować w szczególności przekazywanie danych osobowych odbiorcy w kraju, który
                          zgodnie z decyzją Komisji Europejskiej, zapewnia odpowiednią ochronę danych osobowych, bądź
                          przekazanie danych osobowych odbywać się będzie z zastrzeżeniem odpowiednich zabezpieczeń, o
                          których mowa w art. 46 RODO.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 5 Okres przechowywania danych</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          Dane osobowe będą przechowywane przez okres niezbędny do zrealizowania celów, dla których
                          zostały zebrane, tj.:
                          <ol type="a">
                            <li>korzystania z Aplikacji, a także po zakończeniu przez Użytkownika korzystania z
                              Aplikacji na czas zgodny z przepisami prawa (do upływu terminów dochodzenia roszczeń oraz
                              do upływu okresów wyznaczonych przepisami prawa na przechowywanie dokumentów księgowych),
                            </li>
                            <li>w przypadku danych przetwarzanych na podstawie zgody Użytkownika - do czasu cofnięcia
                              zgody,
                            </li>
                            <li>w przypadku gdy dane osobowe będą przetwarzane w celu realizacji prawnie uzasadnionych
                              interesów Administratora lub osoby trzeciej, o których mowa w niniejszej Polityce
                              Prywatności, przez okres do czasu realizacji tych interesów lub wniesienia sprzeciwu wobec
                              takiego przetwarzania, o ile nie zajdzie sytuacja, w której RODO dopuszcza dalsze
                              przetwarzanie danych,
                            </li>
                            <li>w zakresie realizacji obowiązku prawnego ciążącego na Administratorze przez okres i w
                              zakresie wymaganym przez przepisy prawa, a po tym czasie przez okres wynikający z
                              przepisów prawa lub dla realizacji uzasadnionych interesów Administratora, w tym dla
                              zabezpieczenia i dochodzenia ewentualnych roszczeń.
                            </li>
                          </ol>
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 6 Pliki Cookies oraz pamięć podręczna
                        przeglądarki</h6>
                      <ol className="privacy-page__main-list">
                        <li>
                          W Serwisie oraz w Aplikacji mogą być stosowane pliki cookies (tzw. „ciasteczka”) podmiotów
                          trzecich obejmujące dane informatyczne, w szczególności pliki tekstowe, które przechowywane są
                          w urządzeniu końcowym Użytkownika, a które mogą obejmować następujące rodzaje plików cookies:
                          <ol type="a">
                            <li>cookies „sesyjne” (session cookies) – stanowiące pliki tymczasowe, które przechowywane
                              są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia Serwisu, Aplikacji
                              lub wyłączenia oprogramowania (właściwej przeglądarki internetowej);
                            </li>
                            <li>cookies „stałe” (persistent cookies) – stanowiące pliki przechowywane w urządzeniu
                              końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich
                              usunięcia przez Użytkownika.
                            </li>
                            <li>„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu
                              lub Aplikacji, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających
                              uwierzytelniania w ramach Serwisu lub Aplikacji;
                            </li>
                            <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania
                              nadużyć w zakresie uwierzytelniania w ramach Serwisu lub Aplikacji;
                            </li>
                            <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika
                              ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub
                              regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej
                              itp.;
                            </li>
                            <li>„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych
                              bardziej dostosowanych do ich zainteresowań;
                            </li>
                            <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania
                              ze stron internetowych Serwisu lub Aplikacji.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Użytkownicy mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies.
                          Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną
                          obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich
                          każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o
                          możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
                          (przeglądarki internetowej) np. na poniższych stronach:
                          <ol type="a">
                            <li>Firefox: <a href="http://support.mozilla.org/pl/kb/ciasteczka" target='_blank'>www</a>,
                            </li>
                            <li>Internet Explorer: <a target='_blank'
                                                      href="https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d">www</a>,
                            </li>
                            <li>Chrome: <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647"
                                           target='_blank'>www</a>,
                            </li>
                            <li>Opera: <a href="http://help.opera.com/Linux/12.10/pl/cookies.html"
                                          target='_blank'>www</a>,
                            </li>
                            <li>Safari: <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL&locale=pl_PL"
                                           target='_blank'>www</a>.
                            </li>
                          </ol>
                        </li>
                        <li>W ramach Aplikacji Administrator wykorzystuje do przechowywania danych informatycznych
                          mechanizmy localStorage oraz sessionStorage, tj. wydzieloną część pamięci podręcznej
                          przeglądarki, służącą do przechowywania danych o ruchu w Aplikacji. Dane przechowywane w
                          sessionStorage są usuwane z chwilą zamknięcia przeglądarki przez Użytkownika, zaś dane z
                          localStorage są przechowywane również po jej zamknięciu.
                        </li>
                      </ol>

                      <h6 className="privacy-page__list-header">§ 7 Postanowienia końcowe</h6>
                      <ol className="privacy-page__main-list">
                        <li>Administrator zastrzega sobie prawo do zmiany niniejszej Polityki Prywatności w dowolnym
                          czasie.
                        </li>
                        <li>W celu uzyskania informacji dotyczących świadczenia usług drogą elektroniczną, w tym
                          związanych z użytkowaniem Aplikacji prosimy zapoznać się również z naszym Regulaminem
                          udostępnionym pod adresem: <Link to="/regulations">www.kartazgloszen.pl/regulations</Link>.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </>
  );
}
